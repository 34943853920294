

* {
	box-sizing: border-box;
	padding: 0;
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
	margin: 0;
  }
  
*::-webkit-scrollbar {
	display: none;
}

video::-webkit-media-controls {
	display: none;
  }

  @font-face {
	font-family: 'Inter Tight';
	font-style: normal;
	font-weight: normal;
	src: local('Inter Tight'), url('./fonts/InterTight.ttf') format('ttf');
  }
  
  .communityPlanet::before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border-radius:50%;
	background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(4,215,177,1) 100%);
	-webkit-mask:radial-gradient(farthest-side, transparent calc(100% - 1px),#fff 0);
  }

  .circleBorder::before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border-radius:50%;
	background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0));
	-webkit-mask:radial-gradient(farthest-side, transparent calc(100% - 1px),#fff 0);
  }
  
  .creatorPlanet::before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border-radius:50%;
	background: linear-gradient(#FFFFFF, #FFFFFF, #3C3C3C, #404040, #FFFFFF);
	-webkit-mask:radial-gradient(farthest-side, transparent calc(100% - 1px),#fff 0);
  }
  

  
  
  .video-header {
	height: 100vh;
	width: 100vw;
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	opacity: 0.2
  }


video {
	object-fit: cover;
	pointer-events: none;
}



  body {
	background-color: #090909 !important;
	color: white;

  }
  
  a {
	color: inherit;
	text-decoration: none;
  }
  
  
 #root {
	background: transparent;
	color: white
  }

  html {
	scroll-behavior: smooth;
  }